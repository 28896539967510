<template>
	<v-container fluid style="max-width: 1200px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('courses.searchTitle') }}</span>
		</div>
		<div class="mx-2 my-3">
			<span class="text-h5">{{ $t('courses.searchSubtitle') }}</span>
		</div>

		<v-divider class="my-2" />

		<div class="d-flex align-center" v-if="setupInfo.studies.verifiedOpenedStudies && setupInfo.accountType.student.value">
			<v-icon class="mx-4"> mdi-magnify </v-icon>
			<v-text-field v-model="searchedName" :placeholder="$t('search.search')" />
			<v-dialog v-model="filtersEditMode" max-width="1000px" @click:outside="filtersSwitchEditMode()">
				<template v-slot:activator="{ on }">
					<v-btn color="secondary" v-on="on" elevation="2" rounded small text>
						<v-icon class="mx-4"> mdi-filter </v-icon>
						{{ $t('search.filter') }}
					</v-btn>
				</template>
				<v-card rounded="xl">
					<v-card-title>
						<span class="headline">{{ $t('search.filters') }}</span>
						<v-spacer />
						<v-btn icon color="secondary" @click="filtersSwitchEditMode()">
							<v-icon class="mx-4"> mdi-close </v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" md="6" sm="12">
								<v-select
									v-model="filters.degrees"
									:label="$t('studies.studies')"
									:items="degreesItems"
									item-text="name"
									item-value="id"
									chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
								>
									<template v-slot:selection="data">
										<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
											{{ data.item.name }}
										</v-chip>
									</template>
									<template v-slot:item="data">
										<v-list-item-content>
											<v-list-item-title style="white-space: normal"> ({{ data.item.type }}) {{ data.item.name }} </v-list-item-title>
										</v-list-item-content>
									</template>
								</v-select>
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-select
									v-model="filters.semesters"
									:label="$t('courses.semesters')"
									:items="availableSemesters"
									item-text="name"
									item-value="key"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
								/>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>

		<template v-if="setupInfo.studies.verifiedOpenedStudies && setupInfo.accountType.student.value">
			<v-fade-transition group hide-on-leave>
				<template v-if="loading">
					<v-skeleton-loader v-for="i in 5" :key="`loadingResult${i}`" type="list-item-avatar-three-line" class="mx-auto mb-5" max-width="1200" />
				</template>

				<template v-else-if="availableCourses.length">
					<v-expansion-panels :key="'transition-skeleton'" focusable hover flat>
						<v-expansion-panel v-for="course in filteredCourses" :key="`course${course.id}`" class="rounded-xl my-1">
							<CourseSearchCard :course="course" />
						</v-expansion-panel>
					</v-expansion-panels>

					<template v-if="!filteredCourses.length">
						<h4 class="m-4 text-center" key="noResultsCriteria">
							{{ $t('search.noResultsCriteria') }}
						</h4>
					</template>
				</template>

				<template v-else>
					<div key="noItemsData" class="mx-auto">
						<div class="mt-2 mb-5 text-center">
							<v-icon size="128" role="img"> mdi-package-variant </v-icon>
							<br />
							<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
						</div>
					</div>
				</template>
			</v-fade-transition>
		</template>
		<template v-else>
			<div key="noItemsData" class="mx-auto">
				<div class="mt-2 mb-5 text-center">
					<v-icon size="128" role="img"> mdi-package-variant </v-icon>
					<br />
					<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
				</div>
				<v-list class="m-4 mx-auto" rounded max-width="600">
					<v-subheader>{{ $t('courses.noCoursesNoVerifiedOpenedDegree') }}</v-subheader>
					<v-list>
						<v-list-item three-line>
							<v-list-item-icon>
								<v-icon>mdi-numeric-1-circle-outline</v-icon>
							</v-list-item-icon>
							<v-list-item-content class="text-justify">
								{{ $t('courses.noCoursesNoVerifiedOpenedDegreeReason1') }}
							</v-list-item-content>
						</v-list-item>
						<v-list-item three-line>
							<v-list-item-icon>
								<v-icon>mdi-numeric-2-circle-outline</v-icon>
							</v-list-item-icon>
							<v-list-item-content class="text-justify">
								{{ $t('courses.noCoursesNoVerifiedOpenedDegreeReason2') }}
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-list>
			</div>
		</template>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function (letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function (match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	metaInfo() {
		return {
			title: this.$t('courses.addCourse'),
		}
	},
	data() {
		return {
			loading: true,
			filtersEditMode: false,
			searchedName: '',
			filters: {
				semesters: [],
				degrees: [],
			},
		}
	},
	components: {
		CourseSearchCard: () => import('@/components/courses/CourseSearchCard.vue'),
	},
	computed: {
		filteredCourses() {
			return this.availableCourses.filter(
				(course) =>
					(!this.filters.degrees.length || course.degrees.some((degree) => this.filters.degrees.map((e) => e.id).includes(degree.id))) &&
					(!this.filters.semesters.length ||
						this.courseSemestersKeys(course).some((semester) => this.filters.semesters.map((e) => e.key).includes(semester.key))) &&
					(removeAccents(course.name).toLowerCase().includes(removeAccents(this.searchedName).toLowerCase()) ||
						removeAccents(course.shortDescription).toLowerCase().includes(removeAccents(this.searchedName).toLowerCase()))
			)
		},
		degreesItems() {
			const items = []
			this.faculties.forEach((faculty) => {
				items.push({ header: faculty.name })
				faculty.degrees.forEach((degree) => {
					items.push(degree)
				})
				items.push({ divider: true })
			})
			return items
		},
		...mapGetters({
			availableCourses: 'courses/availableCourses',
			faculties: 'faculties/faculties',
			availableSemesters: 'data/availableSemesters',
			setupInfo: 'user/setupInfo',
		}),
	},
	created() {
		this.fetchFaculties()
		this.fetchSemesters()

		this.fetchAvailableCourses().then(() => {
			this.loading = false
		})
	},
	methods: {
		filtersSwitchEditMode() {
			this.filtersEditMode = !this.filtersEditMode
		},
		courseSemestersKeys(course) {
			var semesters = []
			course.degrees.forEach((degree) => {
				degree.semesters.forEach((semester) => {
					if (!semesters.includes(semester)) {
						semesters.push(semester)
					}
				})
			})
			return semesters
		},
		...mapActions('courses', ['fetchAvailableCourses']),
		...mapActions('faculties', ['fetchFaculties']),
		...mapActions('data', ['fetchSemesters']),
	},
}
</script>

<style scoped></style>
